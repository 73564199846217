import React from "react";
import {Link} from "gatsby"
import Layout from "../../../../../components/layout"
import JobsTable from "../../../../../components/JobsTable";
import z2Logo from "../../../../../images/west.svg"
import HeaderEN from "../../../../../components/HeaderEN";
import FooterEN from "../../../../../components/FooterEN";

const columns = [
    {id: 'job', label: 'Positions', minWidth: 170, link: "link"},
];

function createData(job, link) {
    return {job, link};
}


const rows = [
        createData('Registered Nurse', 'https://gnb1.hiringplatform.ca/24372-registered-nurse/76442-registration/en'),
        createData('Licensed Practical Nurse', 'https://gnb1.hiringplatform.ca/24373-licensed-practical-nurse/76445-registration/en'),
        createData('Nurse Practitioner', 'https://gnb1.hiringplatform.ca/24374-nurse-practitioner/76448-registration/en'),
        createData('Pharmacist', 'https://gnb1.hiringplatform.ca/24375-pharmacist/76451-registration/en'),
        createData('Pharmacy Technician', 'https://gnb1.hiringplatform.ca/24376-pharmacy-technician/76454-registration/en'),
        createData('Diagnostic Imaging Technologist', 'https://gnb1.hiringplatform.ca/24377-diagnostic-imaging-technologist/76457-registration/en'),
        createData('Administrative Assistant  ', 'https://gnb1.hiringplatform.ca/24378-administrative-assistant/76460-registration/en'),
        createData('Social Worker', 'https://gnb1.hiringplatform.ca/24379-social-worker/76463-registration/en'),
        createData('Psychologist', 'https://gnb1.hiringplatform.ca/24380-psychologist/76466-registration/en'),
        createData('Patient Care Attendant', 'https://gnb1.hiringplatform.ca/24381-patient-care-attendant/76469-registration/en'),
        createData('Personal Support Worker', 'https://gnb1.hiringplatform.ca/24382-personal-support-worker/76472-registration/en'),
        createData('Health Information Management Professionals (HIM)', 'https://gnb1.hiringplatform.ca/24383-health-information-management-professionals-him/76475-registration/en'),
        createData('Healthcare Managers', 'https://gnb1.hiringplatform.ca/24385-healthcare-managers/76481-registration/en'),
        createData('Respiratory Therapist', 'https://gnb1.hiringplatform.ca/24386-respiratory-therapist/76484-registration/en'),
        createData('Occupational Therapists', 'https://gnb1.hiringplatform.ca/24387-occupational-therapists/76487-registration/en'),
        createData('Physiotherapist', 'https://gnb1.hiringplatform.ca/24388-physiotherapist/76490-registration/en'),
        createData('Rehabilitation Assistant', 'https://gnb1.hiringplatform.ca/24389-rehabilitation-assistant/76493-registration/en'),
        createData('Speech Language Pathologist', 'https://gnb1.hiringplatform.ca/23986-speech-language-pathologist/75168-registration/en'),

    ]
;
const index = () => (
    <Layout>
        <HeaderEN/>
        <div className={"container-xxl tac-lg"}>
            <Link to="/en/health/">
                <button className={"mb3-lg"}>Back to Full Map</button>
            </Link>
            <div className={"c jc ac"}>
                <h2 className={"py4"}>Open Positions</h2>
            </div>
        </div>
        <div className={"container-xxl r-c-lg mt5 mt0-lg jc"}>
            <div className={"c w50 jfs ac w100-lg"}>
                <img className={"m3"} width="300" height="300" src={z2Logo} alt="Moncton Area"/>
            </div>
            <div className={"c w50 jc ac w100-lg"}>
                <JobsTable rows={rows} columns={columns}/>
            </div>
        </div>
        <FooterEN/>
    </Layout>
);

export default index;