import React from "react";
import horizonLogo from "../images/horizon-logo.png"
import vitaliteLogo from "../images/logo-vitalite2020.png"
import medaviLogo from "../images/medavie-logo.png"
import nursingLogo from "../images/NBANH_horizontal.png"
import {Link} from "gatsby"

export default function FooterEN() {
    return (<>
            <div className={"container-xl"}>
                <div className={"r jc ac mt0 fww"}>
                    <img className={"mx4 p3"} width="220" src={horizonLogo} alt="Horizon Logo"/>
                    <img className={"mx4 p3"} width="220" src={vitaliteLogo} alt="Vitalité Logo"/>
                    <img className={"mx4 p3"} width="220" src={medaviLogo} alt="Medavie Logo"/>
                    <img className={"mx4 p3"} width="220" src={nursingLogo} alt="NBANH Logo"/>
                </div>
            </div>
            <div className={"container-xxl footer mb6"}>
                <hr/>
                <div className={"r-c-lg jsb ac"}>
                    <div className={"r"}>
                        <p className={"mb0 tac-sm"}>All content © Government of New Brunswick. All rights reserved.</p>
                    </div>
                    <div className={"r-c-sm mt4-lg"}>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en.html?_ga=2.172893923.1322334766.1613581688-1203811668.1613581688">
                            Feedback
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/corporate/connect.html">
                            Social Media
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/admin/privacy.html">
                            Privacy
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/en/admin/disclaimer.html">
                            Disclaimer
                        </Link>
                    </div>
                </div>
                {/*<div className={"r jc-lg"}>*/}
                {/*    <p className={"py3 "}><Link className={"c-green"} target={"_blank"} to={"https://vidcruiter.com/"}>Powered*/}
                {/*        by VidCruiter</Link></p>*/}
                {/*</div>*/}
            </div>
        </>
    );
}