import gnbLogo from "../images/NB-LOGO.svg";
import React from "react";
import {Link} from "gatsby"
import Helmet from "react-helmet";
import SEO from "./seo";

export default function HeaderEN(props) {
    return (<>
        <Helmet
            bodyAttributes={{
                class: 'en-page'
            }}
        />
        <SEO description="New Brunswick Job Connector" title="New Brunswick Job Connector"/>
        <div className={"b-gradient"}/>
        <div className={"container-xxl"}>
            <div className={"r-c-lg ac jsb"}>
                <Link to="/"><img className={"my4"} width="216" height="93" src={gnbLogo} alt="GNB Logo"/></Link>
                <div className={"c jc afe ac-lg"}>
                    <h2 className={"tar tac-lg mb2-lg"}>Welcome to the New Brunswick</h2>
                    <h2 className={"tar tac-lg mb3-lg"}>Healthcare Job Connector</h2>
                    <p className={"tar mb0 fs-small tac-lg mt1 pb3-lg c-grape"}><b>Discovery Interviews from March 15th
                        - 29th</b>
                    </p>
                    <Link className={"balance-link"} target="_blank"
                          to={"https://www2.gnb.ca/content/gnb/en/corporate/promo/find_your_balance.html"}>
                        <p className={"tar mb0 fs-small tac-lg mt1 pb3-lg c-grape"}>Click here to learn more about
                            living and working in New Brunswick</p>
                    </Link>
                </div>
            </div>
            <hr className={"mb5"}/>
        </div>
    </>)
}